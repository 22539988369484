<template>
  <li
    v-if="canViewHorizontalNavMenuHeaderLink(item) && item.parent === $route.path.split('/')[1]"
    class="nav-item"
    :class="{'sidebar-group-active active': isActive}"
  >
    <b-link
      class="nav-link"
      :to="{ name: item.route, params: item.params, query: item.query }"
    >
      <b-img
        v-if="item.customIcon"
        :src="require(`@/assets/images/icons/${item.icon}`)"
        class="mr-50"
        width="17"
        height="17"
        :class="!isActive ? 'not-active-icon' : ''"
      />
      <feather-icon
        v-else
        :icon="item.icon"
        size="14"
      />
      <!-- <span>{{ t(item.title) }}</span> -->
      <span>{{ item.title }}</span>
    </b-link>
  </li>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import useHorizontalNavMenuHeaderLink from '@core/layouts/layout-horizontal/components/horizontal-nav-menu/components/horizontal-nav-menu-header-link/useHorizontalNavMenuHeaderLink'
import mixinHorizontalNavMenuHeaderLink from '@core/layouts/layout-horizontal/components/horizontal-nav-menu/components/horizontal-nav-menu-header-link/mixinHorizontalNavMenuHeaderLink'

export default {
  components: {
    BLink,
    BImg,
  },
  mixins: [mixinHorizontalNavMenuHeaderLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, updateIsActive } = useHorizontalNavMenuHeaderLink(props.item)

    const { t } = useI18nUtils()
    const { canViewHorizontalNavMenuHeaderLink } = useAclUtils()

    return {
      isActive,
      updateIsActive,

      // ACL
      canViewHorizontalNavMenuHeaderLink,

      // i18n
      t,
    }
  },
}
</script>
