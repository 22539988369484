export default [
  {
    title: 'Agencies',
    icon: 'agency.svg',
    customIcon: true,
    children: [
      {
        title: 'Agents',
        route: 'agency-agents-index',
        icon: 'agents-black.svg',
        customIcon: true,
        resource: 'Agent',
        action: 'read',
        parent: 'agencies',
      },
      {
        title: 'Agent Requests',
        route: 'agencies-agent-profile-update-index',
        icon: 'AlertCircleIcon',
        parent: 'agencies',
        resource: 'AgentRequest',
        action: 'read',
      },
      {
        title: 'FAQ',
        icon: 'HelpCircleIcon',
        parent: 'agencies',
        key: 'agencies faq',
        children: [
          {
            title: 'Articles',
            route: 'agencies-articles-index',
            icon: '',
            params: {
              type: 'all-article',
            },
            resource: 'AgentArticle',
            action: 'read',
          },
          {
            title: 'Article Categories',
            route: 'agencies-article-categories-index',
            icon: '',
            params: {
              type: 'all-article-category',
            },
            resource: 'AgentArticleCategory',
            action: 'read',
          },
        ],
      },
    ],
  },
]
