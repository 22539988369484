<template>
  <li
    v-if="canViewHorizontalNavMenuHeaderGroup(item) && item.parent === $route.path.split('/')[1]"
    class="dropdown nav-item"
    :class="{
      'sidebar-group-active active open': isActive,
      'show': isOpen,
    }"
    @mouseenter="() => updateGroupOpen(true)"
    @mouseleave="() => updateGroupOpen(false)"
  >
    <b-link class="nav-link dropdown-toggle d-flex align-items-center">
      <b-img
        v-if="item.customIcon"
        :src="require(`@/assets/images/icons/${item.icon}`)"
        class="mr-50"
        width="17"
        height="17"
        :class="!isActive ? 'not-active-icon' : ''"
      />
      <feather-icon
        v-else
        size="14"
        :icon="item.icon"
      />
      <!-- <span>{{ t(item.header) }}</span> -->
      <span>{{ item.header }}</span>
    </b-link>
    <ul class="dropdown-menu">
      <component
        :is="resolveHorizontalNavMenuItemComponent(child)"
        v-for="child in item.children"
        :key="child.key || child.title"
        :item="child"
      />
    </ul>
  </li>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { resolveHorizontalNavMenuItemComponent } from '@core/layouts/utils'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useHorizontalNavMenuHeaderGroup from '@core/layouts/layout-horizontal/components/horizontal-nav-menu/components/horizontal-nav-menu-header-group/useHorizontalNavMenuHeaderGroup'
import mixinHorizontalNavMenuHeaderGroup from '@core/layouts/layout-horizontal/components/horizontal-nav-menu/components/horizontal-nav-menu-header-group/mixinHorizontalNavMenuHeaderGroup'

import HorizontalNavMenuGroup from './HorizontalNavMenuGroup.vue'
import HorizontalNavMenuLink from './HorizontalNavMenuLink.vue'

export default {
  components: {
    BLink,
    BImg,
    HorizontalNavMenuGroup,
    HorizontalNavMenuLink,
  },
  mixins: [mixinHorizontalNavMenuHeaderGroup],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      isActive,
      updateIsActive,
      isOpen,
      updateGroupOpen,
    } = useHorizontalNavMenuHeaderGroup(props.item)

    const { t } = useI18nUtils()
    const { canViewHorizontalNavMenuHeaderGroup } = useAclUtils()

    return {
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,
      resolveHorizontalNavMenuItemComponent,

      // ACL
      canViewHorizontalNavMenuHeaderGroup,

      // i18n
      t,
    }
  },
}
</script>
